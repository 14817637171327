@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/titillium-web/woff/titilliumweb-black-webfont.woff2') format('woff2'),
         url('../fonts/titillium-web/woff/titilliumweb-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/titillium-web/woff/titilliumweb-bold-webfont.woff2') format('woff2'),
         url('../fonts/titillium-web/woff/titilliumweb-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/titillium-web/woff/titilliumweb-extralight-webfont.woff2') format('woff2'),
         url('../fonts/titillium-web/woff/titilliumweb-extralight-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/titillium-web/woff/titilliumweb-extralightitalic-webfont.woff2') format('woff2'),
         url('../fonts/titillium-web/woff/titilliumweb-extralightitalic-webfont.woff') format('woff');
    font-weight: 100;
    font-style: italic;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/titillium-web/woff/titilliumweb-italic-webfont.woff2') format('woff2'),
         url('../fonts/titillium-web/woff/titilliumweb-italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/titillium-web/woff/titilliumweb-light-webfont.woff2') format('woff2'),
         url('../fonts/titillium-web/woff/titilliumweb-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/titillium-web/woff/titilliumweb-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/titillium-web/woff/titilliumweb-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/titillium-web/woff/titilliumweb-regular-webfont.woff2') format('woff2'),
         url('../fonts/titillium-web/woff/titilliumweb-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/titillium-web/woff/titilliumweb-semibold-webfont.woff2') format('woff2'),
         url('../fonts/titillium-web/woff/titilliumweb-semibold-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/titillium-web/woff/titilliumweb-semibolditalic-webfont.woff2') format('woff2'),
         url('../fonts/titillium-web/woff/titilliumweb-semibolditalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/titillium-web/woff/titilliumweb-black-webfont.woff2') format('woff2'),
         url('../fonts/titillium-web/woff/titilliumweb-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;

}