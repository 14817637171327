@import "./_variables.scss";
@import "./_font-face.scss";
@import "./print.scss";

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  background-color: #272b32;
  line-height: 1.4;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  color: darken($gray, 15%);
  scrollbar-width: 10px;
  scrollbar-color: #f0f1f1;
  min-height: 100vh;
  background-attachment: fixed;
}

#root {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

a {
  color: $secondary-color;
  text-decoration: none;
  transition: color 0.1s ease-in;
}
a:hover {
  color: desaturate($color: $secondary-color, $amount: 50%);
}
img {
  max-width: 100%;
  height: auto;
}
#cv {
  margin: 3rem auto 2rem;
  width: 98%;
  max-width: 1300px;
  background-color: rgba(245, 245, 245, 0.97);
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  border-radius: 0.3rem;
  position: relative;

  @media (min-width: $breakpoint-md) {
    margin: auto 0 0 3rem;
    width: calc(100% - 4rem);
  }
}

.page {
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 100%;
}

.main {
  flex: 1 1 305px;
  max-height: 100%;
}

.container {
  width: 98%;
  margin: auto;
}

.w-100 {
  width: 100%;
}

.block {
  padding: 1rem;
  margin: 0 auto 1rem;
  background: transparentize($primary-color, 0.05);
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &--clearbg {
    background: none;
    padding: 0 0.5rem;
    box-shadow: none;
  }
}

.block__title {
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  margin: 1rem 0 0.5rem;
  min-height: 2.4rem;
  gap: 0.5rem;
}

.block__title--text {
  display: block;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.1;
  margin: 0;
}

.block__title--smaller {
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: none;
}

.block__content {
  overflow: hidden;
  border-radius: 0.4rem;
  padding: 1rem;
  background: #fefefe;
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.02);
  position: relative;
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.block__content--clearbg {
  background: none;
  margin: 0 0.5rem;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.content__header {
  position: relative;
}

.clickable .content__header {
  padding-right: 3rem;
}

.details__paragraph {
  margin: 0 0 1rem;
}

.content__toggle {
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  height: 1.5rem;
}
.block__content.clickable:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.06);
}
.block__content.clickable:hover .content__toggle {
  opacity: 0.7;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  transform: scale(0.85);
}
.content__toggle--img {
  width: 1rem;
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.expanded .content__toggle--img {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.content__timeline {
  font-weight: 100;
  font-size: 0.8rem;
  position: relative;
  display: inline-block;
  background-color: transparentize($color: $black, $amount: 0.5);
  padding: 0.125em 0.5em;
  margin: 0 0 0.25em;
  color: $primary-color;
  border-radius: $border-radius;
}
.content__environment {
  @extend .content__timeline;
  margin-left: 0.5rem;
  background-color: transparentize($color: $gray, $amount: 0.5);
}
.content__title {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
  color: darken($gray, 5%);
}
.content__subtitle {
  font-size: 1.1rem;
}
.content__details {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
}
.expanded .content__details {
  opacity: 1;
  visibility: visible;
  max-height: 1000px;
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
ul.content__details--list {
  list-style-type: none;
  padding-top: 0.5rem;
}
ul.content__details--list > li {
  position: relative;
  margin-bottom: 0.5rem;
}
ul.content__details--list > li:before {
  content: "";
  position: absolute;
  font-size: 1rem;
  left: -1.2rem;
  height: 100%;
  width: 0.5em;
  background-image: url(../images/bullet.png);
  background-repeat: no-repeat;
  -webkit-background-size: 80%;
  background-size: 80%;
  background-position: center 0.5em;
  opacity: 0.5;
}
.badge {
  background: transparentize($color: $primary-color, $amount: 0.05);
  display: inline-block;
  border-radius: 0.3rem;
  box-shadow: 0 2px 24px rgb(0 0 0 / 5%);
  padding: 0.1rem 0.3rem;
  margin: 0 0.3rem 0.3rem 0;
  border: 1px solid transparentize($color: $gray, $amount: 0.75);
}

footer {
  padding: 2% 2% 0;
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin: auto auto 0;
}
.footer__content {
  width: 100%;
  height: 100%;
}

#cv ::-webkit-scrollbar {
  width: 10px;
}
#cv ::-webkit-scrollbar-track {
  background: #ccc;
}
#cv ::-webkit-scrollbar-thumb {
  background: lighten($secondary-color, 5%);
  transition: all 0.2s ease-in-out;
}
#cv ::-webkit-scrollbar-thumb:hover {
  background: $secondary-color;
}
@media (min-width: 1640px) {
  #cv {
    max-width: 1600px;
  }
}
@media (min-width: 892px) and (min-aspect-ratio: 3/2) {
  .main--experience {
    max-height: 80vh;
    overflow: auto;
  }
}

@media (min-width: 892px) and (max-aspect-ratio: 3/2) {
  .main--experience {
    max-height: 50vh;
    overflow: auto;
    flex: 1 0 100%;
  }
}
@media (max-width: 560px) {
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f0f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  #cv {
    height: auto;
  }
  .main {
    max-width: none;
    width: auto;
  }
  .block {
    padding: 1rem 0;
  }
}

.print-only {
  display: none;
  @media print {
    display: block;
  }
}
