@import './_variables.scss';
.contact__item {
    display: flex;
    padding: 0.2rem 0 0.3rem;
  }
  .contact__icon {
      height: 2rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-size:12px;
      flex: 0 0 2em;

  }
  .contact__icon svg {
      height: 1rem;
      margin: auto;
      color: $secondary-color;
      
  }
  .contact__link {
    font-size: 1rem;
    line-height: 1.8rem;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: .25rem;
    color: inherit;
  }
  a.contact__link:hover {
    color: desaturate($secondary-color, .5);
  }