@import "./_variables.scss";
.photo {
  display: block;
  margin: auto auto 1rem;
  width: 200px;
  max-width: 80%;
  border-radius: 50%;
  border-bottom: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  padding: 0.2rem;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.02);
}

.name {
  font-family: "Titillium Web", sans-serif;
  margin: 0 auto;
  display: block;
  font-size: 2rem;
  text-align: left;
  padding: 0 0.1rem 0;
  position: relative;
  text-align: center;
  color: darken($gray, 15%);
}

.role {
  font-family: "Titillium Web";
  margin: 0 auto 1.5rem;
  font-size: 1rem;
  color: $gray;
  letter-spacing: 1.4px;
  font-weight: 400;
  text-align: center;
}
