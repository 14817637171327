@keyframes fadeInOut {
  from {
    opacity: 0.4;
  }

  40% {
    opacity: 1;
  }

  to {
    opacity: 0.4;
  }
}
