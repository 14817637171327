@import './_variables.scss';
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.main.main--portfolio {
    overflow: hidden;
    padding: 0 .5rem 3rem;
}

.portfolio__item {
  background-color: #fefefe;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.portfolio__item:hover {
  cursor: pointer;
//   box-shadow: 0 0 10px 2px rgba(33, 72, 90, 0.25);
}
.portfolio__caption {
  padding: 0.5rem 0;
}

.sliderNav {
    display: flex;
    justify-content: center;
    gap: .5rem;
}

.sliderNav__dot {
    display: block;
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
    background-color: transparentize($color: $gray, $amount: 0.5);
    border: 0;
    outline: 0;
}

hr {
  border-top: 1px solid #cecece;
  margin: 1rem 0;
}

.slick-prev {
    left: -0.25rem;
}

.slick-next {
    right: -.25rem;
}

.slick-next,
.slick-prev {
    
    background: #fff;
    width: auto;
    height: auto;
    display: flex!important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 1;
    top: 33%;
    padding: 2px 2px 1px;
    box-shadow: 0 .125rem .75rem rgba(0,0,0,.1);

    &:before {
        color: $secondary-color;
    }

    &:hover,
    &:focus {
        background: #fff;
    }
}