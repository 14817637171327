@media print {
  html {
    font-size: 11pt;
  }
  body {
    font-size: 11pt;
    background: none !important;
  }
  html,
  body {
    height: 95%;
    max-width: 99%;
  }
  * {
    box-shadow: none !important;
  }
  #cv {
    visibility: visible;
    background: none !important;
    width: 100% !important;
    margin: 0 !important;
    box-shadow: none !important;
    max-width: 100% !important;
    display: block !important;

    & > * {
      grid-column: 1/7;
      overflow: visible !important;
      width: auto !important;
      max-width: none !important;
      display: block !important;
    }

    & > .page {
      display: grid !important;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-gap: 0.25cm 1.5cm !important;
    }

    .block {
      background: none !important;
      padding: 0;
    }

    .block__title {
      display: block;
      margin: 0 0 1rem;
      min-height: 0;
      padding: 0;
    }

    .block__title--text {
      font-size: 1.25em;
    }

    .block__content,
    .block__content.clickable,
    .block__content.expanded {
      border: none;
      padding: 0 !important;
      background: none !important;
    }

    .block__content {
      border-radius: 0;
      margin: 0 0 1rem;
    }

    .content__header {
      border-left: 1px solid rgba(0, 0, 0, 0.25) !important;
      padding: 0 0 0 1rem;
    }

    .content__timeline {
      background: none;
      padding: 0;
      color: $gray !important;
    }

    .content__details {
      padding-top: 1rem;
      padding-left: 1rem;
    }

    .content__details--list {
      padding-top: 0;
      padding-left: 1.5rem;
      margin: 0;
    }

    .content__status {
      display: none !important;
    }
    .sidebar {
      grid-column: 1/3;
      grid-row: 1/2;
      align-self: flex-start;
      margin: 0;
      padding: 0;
    }

    .sidebar__contact {
      padding: 0;

      .row {
        display: block !important;
      }
    }

    .main {
      flex: none;
      min-height: 0;
      max-height: none;
    }

    .main--profile {
      grid-column: 1/3;
      grid-row: 2/3;
      // font-size: .875rem;

      .details__paragraph {
        margin-bottom: 0.5rem;
      }
    }

    .main--experience {
      grid-column: 3/7;
      grid-row: 1/3;
      break-after: page !important;
      //   page-break-after: always!important;
      display: block !important;
    }

    .w-100.break-after {
      grid-row: 3/4;
      width: 100% !important;
      // page-break-after: always!important;
      display: block !important;
    }

    .main--education {
      grid-column: 3/7 !important;
      grid-row: 1/2;
      break-before: page !important;
      // page-break-before: always!important;
      display: block !important;
    }

    .main--skills {
      grid-column: 1/3 !important;
      grid-row: 1/3;
      break-before: page !important;
      page-break-before: always !important;
      display: block !important;
    }

    .main--portfolio {
      grid-column: 3/7 !important;
      grid-row: 2/3;
      display: block !important;
    }

    .main--consent {
      grid-column: 2/6 !important;
      grid-row: 3/4;
      display: block !important;
      font-size: 8pt;
    }

    .portfolio__item hr {
      margin: 0;
      border: 0;
    }
  }
  .noprint {
    visibility: hidden;
    display: none;
    width: 0;
    height: 0;
  }
  .page-wrapper {
    background: none;
    display: block;
  }

  .sidebar__image {
    width: 120px;
    margin: auto auto 0.5rem;
  }

  .main--qrqode {
    text-align: center;
    margin: 0 auto 2rem;
  }
  .surname {
    display: block;
    font-weight: 900;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
  .content__details {
    opacity: 1;
    visibility: visible;
    max-height: 1000px;
  }
  .content__toggle {
    display: none;
  }
  .main {
    // flex: 1 0 100% !important;
    max-height: none;
    width: 100% !important;
    overflow: visible;
  }

  .block__title--img,
  ul.content__details--list > li:before {
    filter: grayscale(1);
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .portfolio__item {
    display: inline-block;
    // max-width: calc(49% - 2rem );
  }
  // .block__content {
  //     page-break-inside: avoid!important;
  // }
  // .break-avoid {
  //     page-break-inside: avoid!important;
  // }
  // .block__title {
  //     page-break-after: avoid!important;
  // }

  .main--experience {
    max-height: none !important;
    overflow: visible !important;
  }

  .main--profile {
    break-inside: avoid !important;
    page-break-after: always !important;
    grid-column: 3/7;
  }

  // .main--education {
  //     page-break-after: always!important;
  // }

  // .main--skills {
  //     break-inside: avoid-page!important;
  // }

  // .main--profile .block {
  //     border: 1px solid #cecece;
  //     background: none!important;
  // }

  .break-after {
    break-after: page !important;
  }

  .container {
    break-inside: avoid-page !important;
  }

  .slick-slider {
    button.slick-arrow,
    .slick-dots {
      display: none !important;
    }

    .slick-track {
      width: 100% !important;
      display: flex;
      gap: 0 / 2rem;
      flex-wrap: wrap;
      transform: translate(0) !important;

      & > .slick-cloned {
        display: none !important;
      }

      & > * {
        width: auto !important;
        max-width: 50%;
        margin-bottom: 1rem;
      }

      .slick-slide img {
        display: none !important;
      }
    }
  }
}
