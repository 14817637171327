@import "./_variables.scss";
@import "./_keyframes.scss";

.content__status {
    background-color: #d8d8d8;
    background-image: -webkit-radial-gradient(#d8d8d8 50%, #f6f6f6);
    background-image: -o-radial-gradient(#d8d8d8 50%, #f6f6f6);
    background-image: radial-gradient(#d8d8d8 50%, #f6f6f6);
    width: 0.625em;
    position: absolute;
    right: .375em;
    top: .375em;
    height: 0.625em;
    margin: .125em 0;
    display: inline-block;
    border-radius: 50%;
    vertical-align: text-top;
    content: "";
    border: 1px solid #f6f6f6;
    opacity: 0.8;
    -webkit-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;

    &.active {
        background-color: #10e118;
        background-image: -webkit-radial-gradient(#10e118 50%, #dbffdb);
        background-image: -o-radial-gradient(#10e118 50%, #dbffdb);
        background-image: radial-gradient(#10e118 50%, #dbffdb);
        border: 1px solid #dbffdb;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: fadeInOut;
      }
  }
  