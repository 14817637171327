@import "./_variables.scss";
button.noprint {
  outline: none;
  background: none;
  border: 0;
  opacity: 0.7;
  transition: all ease-in 0.2s;
  color: transparentize($primary-color, .03);
}
button.noprint:hover {
  opacity: 1;
  cursor: pointer;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: -3rem;
  height: 2rem;

  @media (min-width: $breakpoint-md) {
    top: 1rem;
    position: absolute;
    bottom: 0;
    left: -3rem;
    flex-direction: column;
    width: 3rem;
    height: calc(100vh - 3rem);
  }
}

.printer {
  @media (min-width: $breakpoint-md) {
position: fixed;
bottom: 1rem;
  }
}

.language-selection {
  height: 1.5rem;
  display: flex;
  gap: 0.5rem;

  
  button {
    border: 0;
    background: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: $breakpoint-md) {
    height: auto;
    flex-wrap: wrap;

    button {
      width: 100%;
      padding: 0.375rem 0;
      border-radius: 0.25rem 0 0 0.25rem;
      background: transparentize($primary-color, 0.5);
    }

    button.active {
      background: transparentize($primary-color, 0.03);
    }
  }


  

  button.active img {
    opacity: 1;
    border: 1px solid rgb(245 245 245 / 97%);
    box-shadow: 0 0 0.1rem 0.1rem #96aab5;
    opacity: 1;
  }
}
img.language {
  height: 1.5rem;
  width: 1.5rem;
  object-fit: cover;
  object-position: center;
  margin: 0 0.2rem;
  border-radius: 50%;
  box-shadow: 0 0 0.2rem 0.2rem rgba(0, 0, 0, 0.04);
  opacity: 0.6;
  border: 1px solid transparent;
  transition: all ease-in 0.1s;
}
img.language:hover {
  cursor: pointer;
  box-shadow: 0 0 0.2rem 0.2rem rgba(0, 0, 0, 0.08);
  opacity: 1;
}
.sidebar {
  flex: 0 0 auto;
  /*  background-color: #fafafa;*/
  padding: 1rem 2rem;
  margin: auto;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
}
.sidebar__header {
  padding: 0.5rem 0.2rem 2rem;
}
